<template>
    <div class="Calendar">
        <transition name="van-fade">
            <scroll v-if="!loading&&msgs.length>0" class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                <div class="buyLog">
                    <ul class="msgList">
                        <li v-for="(item,index) in msgs" :key="index" class="noFlag" @click="showDetail(item)">
                            <!-- <span class="time">{{$m_formulateTime(item.deliveryTime)}}</span> -->
                            <span class="time">{{$moment(item.deliveryTime).fromNow()}}</span>
                            <span class="text">
                                <p clsss="clamp2" v-html="item.body.msgBody"></p>
                            </span>
                            <span class="more" @click.stop="moreClick(item)">
                                <i class="iconfont icon-more"></i>
                            </span>
                        </li>
                    </ul>
                </div>
            </scroll>
        </transition>
        <transition name="van-fade">
            <nodata v-if="msgs.length<=0&&!loading"></nodata>
        </transition>
        <transition name="van-fade">
            <loading v-if="loading" size="30px"></loading>
        </transition>
        <!-- 延迟弹窗 -->
        <dialog-delay ref="dialogDelay" @success="getList()" />
        <!-- 弹出详情 -->
        <van-popup v-model="isShowItem" class="showItemBody">{{showItemTxt}}</van-popup>
    </div>
</template>

<script>
import dialogDelay from '../Home/Itinerary/dialogDelay.vue'
import titleMixin from '../../../mixin/title'
import Scroll from '@/components/Scroll/index'
export default {
    name: 'Plan',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.workbench.1584928073327') }, // '日程和提醒'
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: this.$t('mx_mobile.workbench.1584934904411'), // '加载更多'
            pullUpLoadNoMoreTxt: this.$t('mx_mobile.workbench.1584934930735'), // '没有更多数据了'
            startY: 0,
            page: {
                total: 0,
                from: 1,
                size: 10
            },
            loading: false,
            msgs: [],
            isShowItem: false,
            showItemTxt: ''
        }
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    created() {
        this.setMenu()
        this.reGetList()
    },
    methods: {
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        async showDetail(item) {
            this.msgJump(item)
        },
        // 打开更多
        async moreClick(item) {
            try {
                let res = await this.Global.utils.actionSheet.open([
                    {
                        name: this.$t('mx_mobile.workbench.1584934177147'), // '延迟'
                        index: 0
                    },
                    {
                        name: this.$t('mxpcweb.workbench.1630312831487'), // '关闭'
                        index: 1
                    }
                ])
                if (!res) { return }
                switch (res.index) {
                    case 0:
                        this.$refs.dialogDelay.open(item) // 打开延迟
                        break
                    case 1:
                        this.closeMsg(item)
                        break
                    case -1:
                        break
                    default:
                        break
                }
            } catch (e) {
                console.log(e)
            }
        },
        // 关闭消息
        closeMsg(item) {
            this.axios.delete(this.Global.config.apiBaseURL + this.Global.api.v2.messenger_delete, {
                params: {
                    jobName: item.jobName
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success(this.$t('mx_mobile.workbench.1584934471910')) // '关闭成功'
                    this.getList() // 重新加载一下数据
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        async onPullingUp() {
            if (this.msgs.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.msgs.length < this.page.total) {
                this.page.from += 1
                let msgs = await this.getList()
                this.msgs = this.msgs.concat(msgs)
                this.$nextTick(() => {
                    if (this.msgs.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },
        async onPullingDown() {
            this.page.from = 1
            this.checkedItems = []
            this.msgs = await this.getList()
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        async reGetList() {
            this.page.from = 1
            this.loading = true
            this.msgs = await this.getList()
            this.loading = false
        },
        // 获取列表
        async getList() {
            let listArr = []
            try {
                let data = {
                    pageN: this.page.from,
                    pageSize: 20,
                    sort: 'createDate',
                    order: 'desc'
                }
                let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.messenger_get, { params: data })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    listArr = res.data.data.timingTaskList
                    this.msgs = listArr
                    this.page.total = res.data.data.rowTotal
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
            return listArr
        }
    },
    watch: {
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        }
    },
    components: {
        'Scroll': Scroll,
        'dialog-delay': dialogDelay
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.Calendar {
    position: absolute;
    width: 100%;
    top: 44px;
    bottom: 0;
    overflow-y: auto;
    background: #fff;
    .wrapper {
        height: 100% !important;
    }
    .showItemBody {
        // border:11px solid red;
        padding: 1.5rem 1rem;
        border-radius: 8px;
    }
    .clamp2 {
        .line-clamp(2);
    }
    ul.msgList {
        // 日程
        // border:1px solid red;
        padding: 3px 0;
        & > li {
            position: relative;
            // height: 48px;
            // line-height: 48px;
            padding-left: 30px;
            display: flex;
            flex-flow: row;
            align-items: center;
            & > span {
                display: inline-block;
            }
            .more {
                width: 25px;
                margin-right: 10px;
                // border:1px solid red;
                // position: absolute;
                // right: 15px;
                // top: 0;
                // bottom: 0;
                > i {
                    .font-size(22px);
                    color: #b6bac6;
                }
            }
            .time {
                // border:1px solid red;
                display: inline-block;
                padding: 10px 0;
                width: 70px;
                height: 100%;
                font-weight: 600;
                overflow: hidden; // text-overflow: ellipsis;
                white-space: nowrap; // background: red;
            }
            .text {
                flex: 1;
                // border:1px solid blue;
                // position: absolute;
                // left: 96px;
                // right: 52px;
                // top: 0;
                // bottom: 0;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                color: #606266;
                line-height: 1.5;
                padding: 10px 0;
            }
            &::before {
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #b6bac6;
                left: 15px;
                top: 50%;
                margin-top: -4px;
            }
            &.newMsg::before {
                // 新未读消息
                background: #ff7165;
            }
            &.noFlag {
                padding-left: 15px;
                &::before {
                    content: none;
                }
            }
            &::after {
                content: "";
                position: absolute;
                left: 15px;
                right: 10px;
                bottom: 0;
                height: 1px;
                background: #f5f5f5;
            }
            &:last-child::after {
                content: none;
            }
            &:active {
                background: #ddd;
            }
        }
    }
}
.buyLog {
    margin-bottom: 15px;
}
</style>
