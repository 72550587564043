<template>
    <div class="Schedule">
        <van-tabs class="TabBox" v-model="active">
            <!-- 待处理 -->
            <van-tab :title="$t('mx_mobile.workbench.1584932677885')">
                <tab-plan></tab-plan>
            </van-tab>
            <!-- 日程 -->
            <van-tab :title="$t('mx_mobile.workbench.1584933056797')">
                <tab-calendar></tab-calendar>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import plan from './plan.vue'
import calendar from './calendar.vue'
export default {
    name: 'Schedule',
    title: function fc() { return this.$t('mx_mobile.workbench.1584928073327') }, // '日程和提醒'
    data() {
        return {
            active: 0
        }
    },
    created() {
    },
    methods: {
    },
    components: {
        'tab-plan': plan,
        'tab-calendar': calendar
    },
    watch: {
        $route() {
            let path = this.$route.query.id
            this.active = path
        }
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.Schedule {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
}
.TabBox {
    flex: 1;
}
</style>
